import React from 'react';

function FindMyId() {

    return (
        <div className="FindMyId">

            여긴 아이디 찾기
        </div>
    );
}

export default FindMyId;