/******************************************************************
 * 
 *   baccus Recoil State Store
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

import { atom, selector } from 'recoil';

//---
// 로그인 여부 상태관리
//---
export const loginState = atom({
    key : 'loginState',
    default : { isLogin : false }
});

export const getLoginState = selector({
    key : 'getLoginState',
    get : ({get}) => {
        return get(loginState);
    }
})

//---
// 사용자 정보 상태관리
//---
export const userState = atom({
    key : 'userState',
    default : {
        userId : '',
        userName : '',
        shopCode : '',
        shopName : '',
        shopNumber : '',
        shopTel : '',
        mobilePhone : '',
        classification : '',
    }
})

export const getUserState = selector({
    key : 'getUserState',
    get : ({get}) => {
        return get(userState);
    }
})
