import React from 'react';

function FindPassword() {

    return (
        <div className="FindPassword">

            여긴 비밀번호 찾기
        </div>
    );
}

export default FindPassword;