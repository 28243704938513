import React from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';

import Auth from './auth/AuthCheck';
import NotFound from './pages/404';
import Layout from './pages/Layout';
import Home from './pages/Home';

import Login from './pages/Login';
import Notices from './pages/Notices';
//import Notices from './pages/NO'
import OrderSheet from './pages/OrderSheet';
import ShoppingBag from './pages/ShoppingBag';
import AccountBook from './pages/AccountBook';

import MemberJoin from './pages/MemberJoin';
import FindMyId from './pages/FindMyId';
import FindPassword from './pages/FindPassword';

import './App.css';

function App() {

  const SSID = sessionStorage.getItem('SSID');

  const routes = [
    {
      element : <Auth loginState={true}><Layout /></Auth>,
      path : '/',
      children : [
        { index: true, element : <Auth loginState={true}><Home /></Auth> },        
        { element : <Auth loginState={true}><Notices /></Auth>, path : '/baccusnotices/:noticeId' },
        { element : <Auth loginState={true}><OrderSheet /></Auth>, path : '/ordersheet' },
        { element : <Auth loginState={true}><ShoppingBag /></Auth>, path : '/shoppingbag' },
        { element : <Auth loginState={true}><AccountBook /></Auth>, path : '/accountbook' },
      ],
    },
    { element : <Auth loginState={false}><Login /></Auth>, path : '/login' },
    { element : <MemberJoin />, path : '/memberjoin' },
    { element : <FindMyId />, path : '/findmyid' },
    { element : <FindPassword />, path : '/findpassword' },
    { element : <NotFound />, path : '*' },
  ]

  const element = useRoutes(routes);

  return (     
    <div className="App">
      { element }
    </div>
  );
}
export default App;
