/******************************************************************
 * 
 *   baccus Home 모듈
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

import React, { useEffect, useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import Carousel from 'react-material-ui-carousel';
import { apiCall } from '../common-lib/custom-axios';

import { useRecoilState } from 'recoil';
import { loginState, userState } from '../store/user-store';

import '../css/home.css';

const bull = (
    <Box
      component="span"
      sx={{ display: 'inline-block', mx: '2px', transform: 'scale(0.8)', margin: '10 10 10 10px' }}
    >
      •
    </Box>
);

function Home() {

    const [ isLogin, setLoginState ] = useRecoilState(loginState);
    const [ { userId, userName, shopName, shopCode, shopNumber, classification }, setUserState ] = useRecoilState(userState);
    const [ data, setData ] = useState([]);

    const winWidth = window.innerWidth;
    const winHeight = window.innerHeight;

    var img = new Image();
    img.src = 'https://test.eilsin.co.kr/baccusNotice-01.jpg';

    var imgWidth = img.width;
    var imgHeight = img.height;


    let width90 = winWidth * 0.9;
    if(width90 > 600) width90 = 600;
    let height90 = (imgHeight * width90) / imgWidth;

    // console.log(height90)

    // const [ cardWidth, setCardWidth ] = useState(`${width90}px`);
    // const [ cardHeight, setCardHeight ] = useState(`${height90}px`);
    // const [ styled, setStyled ] = useState('');

    // console.log(cardWidth)
    // console.log(cardHeight)

    // useLayoutEffect(() => {
    //     setCardHeight(`${height90}px`)
    //     setCardWidth(`${width90}px`)
    // }, [cardWidth, cardHeight])

    // useEffect(() => {
    //     setCardHeight(`${height90}px`)
    //     setCardWidth(`${width90}px`)
    // }, [cardWidth, cardHeight])
    const [ noticesList, setNoticeList ] = useState([]);

    useEffect(() => {
        getNoticesList();
    }, []);

    useEffect(() => {
        console.log(userId, userName, shopName, shopCode, shopNumber, classification)
        console.log(isLogin)
    }, [shopCode])

    const getNoticesList = async () => {
        const res = await apiCall.get('/baccusnotices').then( res => res.data );

        console.log(res);
        setData(res);
    };

    return (
        <div className="Home">
            <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, minHeight: 180, zIndex: -99999, backgroundColor: '#181A70', borderRadius: 0 }} elevation={0}>
            </Paper>
            <Carousel duration='1000' indicatorContainerProps={{ style: { zIndex: 1, marginTop: "-10px", position: "relative"} }} animation='Fade'>

            { data.map((content, idx) => {
                    return (
                        <>
                            <Box sx={{ minWidth: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                                <Card elevation={0} className='baccus-card' style={{ maxWidth:600, marginTop: '130px', marginBottom: '10px', borderRadius: 10, objectFit: 'cover'}} >
                                    <CardActionArea>
                                        <Link to={`/baccusnotices/${content.id}`}>
                                            <CardMedia component='img' style={{objectFit: 'cover'}} image={content.imageName} />
                                        </Link>
                                    </CardActionArea>
                                </Card>
                            </Box>
                        </>
                )}
              )
            }
                {/* <Box sx={{ minWidth: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center'}}>
                <Card elevation={0} className='baccus-card' style={{ maxWidth:600, marginTop: '130px', marginBottom: '10px', borderRadius: 10, objectFit: 'cover'}} >
                    <CardActionArea>
                        <Link to='/baccus-notices/2'>
                            <CardMedia component='img' style={{objectFit: 'cover'}} image='baccusNotice-01.jpg' />
                        </Link>
                    </CardActionArea>
                </Card>
                </Box>
                <Box sx={{ minWidth: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Card elevation={0} className='baccus-card' style={{maxWidth:600, marginTop: '130px', marginBottom: '10px', borderRadius:10}}>
                    <CardActionArea>
                        <Link to='/baccus-notices/3'>
                            <CardMedia component='img' style={{objectFit: 'cover'}} image='baccusNotice-02.jpg' />
                        </Link>
                    </CardActionArea>
                </Card>
                </Box>
                <Box sx={{ minWidth: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Card elevation={0} className='baccus-card' style={{maxWidth:600, marginTop: '130px', marginBottom: '10px', borderRadius:10}}>
                    <CardActionArea>
                        <Link to='/baccus-notices/4'>
                            <CardMedia component='img' style={{objectFit: 'cover'}} image='baccusNotice-05.jpg' />
                        </Link>
                    </CardActionArea>
                </Card>
                </Box>
                <Box sx={{ minWidth: '100%', justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Card elevation={0} className='baccus-card' style={{ maxWidth:600, marginTop:'130px', marginBottom: '10px', borderRadius:10}}>
                    <CardActionArea>
                        <Link to='/baccus-notices/5'>
                            <CardMedia component='img' style={{objectFit: 'cover'}} image='baccusNotice-03.jpg' />
                        </Link>
                    </CardActionArea>
                </Card>
                </Box> */}
            </Carousel>
            <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Card className='state-card' variant="outlined" style={{maxWidth:600, marginTop:'10px', marginBottom: '10px', background: '#FF6F42', borderRadius:10}}>
                    <CardContent>
                        <Typography sx={{ fontSize: 20 }} color="white" gutterBottom>
                            주문상황 안내
                        </Typography>
                        <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Typography sx={{ fontSize: 14 }} color="white" gutterBottom>
                            2022년 12월 25일(금) 배송예정
                        </Typography> &nbsp; 
                        <IconButton><ArrowCircleRightIcon style={{color:'yellow'}} sx={{fontSize:40}} /></IconButton>
                        </div>
                    </CardContent>
                </Card>
            </Box>
            <Box sx={{ justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                <Card variant="outlined" className='company-card' style={{maxWidth:600, marginTop: '10px', background: 'linear-gradient(90deg, #1590D5 0%, #096EA7 100%)', borderRadius: '10px'}}>
                <div className='star1'  ></div>
                <div className='circle2' ></div>
                  <div style={{ justifyContent: 'left', display: 'flex', alignItems: 'center', textAlign:'left' }} >
                    <CardContent>
                        <Typography sx={{ fontSize: 20 }} color="white" gutterBottom>
                            (유)일신물산 공지사항
                        </Typography>
                        <Typography sx={{ fontSize: 14 }} color="white" gutterBottom>
                            추석연휴간 9월 25일 부터 9월 30일까지는 연휴입니다. 배송에 착오 없도록 미리 주문해주시기 바랍니다.
                        </Typography>
                        <div style={{ justifyContent: 'flex-end', display: 'flex', alignItems: 'center', textAlign:'right', color: '#ffffff' }} >
                            자세히보기<IconButton><ArrowCircleRightIcon style={{color:'#113366'}} sx={{fontSize:40}} /></IconButton>
                        </div>
                    </CardContent>
                  </div>
                </Card>
            </Box>
            <br /><br /><br /><br />
            {/* <Box sx={{ minWidth: '100%', minHeight: 200, justifyContent: 'center', display: 'flex', alignItems: 'center' }}>
                요긴 광고판 위치!!!!
                <img src='baccus192.png' />
            </Box> */}
        </div>
    );
}

export default Home;