/******************************************************************
 * 
 *   baccus 발주처 공지사항 모듈
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button'

import { apiCall } from '../common-lib/custom-axios';

function Notices() {

    const [ data, setData ] = useState({});

    let { noticeId } = useParams(); // 라우터에서 넘어온 id 값을 받아온다.
    let navigate = useNavigate();

    useEffect(() => {
        getNotice(noticeId);
    }, [])

    const getNotice = async (id) => {
        const res = await apiCall.get(`/baccusnotices/${id}`).then( res => res.data );
        
        console.log(res)
        setData(res);
    }

    return (
        <div className="Notices">
            <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, minHeight: 180, zIndex: -99999, backgroundColor: '#181A70', borderRadius: 0 }} elevation={0}>
            </Paper>
            <Box style={{marginTop: 150}} sx={{display:'flex', justifyContent:'center', alignItems: 'center', textAlign:'left', left:0, right:0, marginBottom:5 }} >
            {/* id 값의 공지사항을 읽어온 후에 화면에 보여준다. */}

                <Card variant='outlined' style={{width:'90%', maxWidth: 600}}>
                    <CardMedia  component='img' image={`/${data.imageName}`} />
                    <CardHeader title={data.title} subheader={data.nDate}>
                    </CardHeader>
                    <CardContent>
                        <div style={{whiteSpace: 'pre-wrap', textAlign: 'justify'}}>{data.contents}</div>
                    </CardContent>
                    <center style={{marginTop:20, marginBottom:20}}>
                        <Button variant="outlined" size="medium" onClick={() => navigate(-1)}>
                            돌아가기
                        </Button>
                    </center>
                </Card>
            </Box>
        </div>
    );
}

export default Notices;