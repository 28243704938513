/******************************************************************
 * 
 *   baccus common library - Quantity Counter
 *   2022.10.12 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/
import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

function QTYCounter(props) {


    const [ quantity, setQuantity ] = useState(props.defaultValue);    
                     
    const increQuantity = () => {
        setQuantity(prevState => prevState + 1)
//        props.updateQTY(props.idx, quantity)
        console.log(props, quantity)
    }

    const decreQuantity = () => {
        if(quantity > 1)
        setQuantity(prevState => prevState - 1)
    }

    const quantityChange = (e) => {
        setQuantity(Number(e.target.value));
        //props.updateQTY(props.index);
    }

    useEffect(() => {
        console.log(quantity)
    }, [quantity])

    // onFocus={(e) => e.target.select()}  이게 뜨면 전체 셀렉트 복사메뉴가 떠버림 ㅠ.ㅠ;;
    return (
        <div style={{display:'flex', border: '1px solid #cfcfcf', borderRadius:5, padding: '2px', maxWidth:116, width:86, height:24, alignItems:'center'}}>
            <Button onClick={() => {decreQuantity(); props.updateQty(props.idx, quantity - 1)}} size="small" variant="contained" disableElevation sx={{minWidth:24, maxWidth:24, height:24}}><RemoveIcon fontSize="inherit" /></Button>
            {/* <div style={{width:30, textAlign:'right'}}>{quantity}</div> */}
            <input className='quantity' maxlength="3" value={quantity} style={{textAlign:'right', marginLeft:'2px', fontSize:'14', height:20}} onChange={(e) => {quantityChange(e)}} />
            <Button onClick={() => {increQuantity(); props.updateQty(props.idx, quantity + 1)}} size="small" variant="contained" disableElevation sx={{minWidth:24, maxWidth:24, height:24, marginLeft:'4px'}}><AddIcon fontSize="inherit"/></Button>
        </div>
    );
}

export default QTYCounter;