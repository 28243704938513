import React from 'react';

function MemberJoin() {

    return (
        <div className="MemberJoin">

            여긴 mebmer Join 
        </div>
    );
}

export default MemberJoin;