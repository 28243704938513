/******************************************************************
 * 
 *   baccus Not Found 모듈 (권한이 없거나 허용되지 않는 페이지 혹은 없는 페이지 접근시에)
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

 import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import { yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
}));

function NotFound() {
    let navigate = useNavigate();

    const handleClick = () => {
        navigate('/login');
    }

    return (
        <div className="NotFound" style={{ position: 'absolute',
                                           width: '100vw',
                                           height: '100vh',
                                           background: 'linear-gradient(168.55deg, #FFDF37 0%, #FF37AF 43.75%, #742ECE 100%)' }}>
            <div style={{fontSize:'120px', fontWeight: 'bold', color: '#efefef'}}>404</div>
            <div style={{fontSize:'40px', fontWeight: 'bold', color: '#efefef'}}>Page Not Found.</div>
            <p />
            <div style={{fontSize:'24px', fontWeight: 'bold', color: '#efefef'}}>잘못된 페이지 접근이거나 존재하지 않는 페이지를 찾습니다.</div>
            <br /><br /><br />

            <ColorButton variant="contained" onClick={handleClick}>로그인으로</ColorButton>
        </div>
    );
}

export default NotFound;