/******************************************************************
 * 
 *   baccus 거래장 모듈
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';

function AccountBook() {

    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handlePhone = (number) => {
        window.location.href = 'tel:' + number;
    }
  
  
    return (
        <div className="AccountBook" >
            <Box sx={{ maxWidth: { xs: '100vw', sm: '100vw' } }} style={{marginTop: 150}}>
                <TabContext value={value}>
                <div style={{display: 'inline-block', position: 'fixed', zIndex: 999, top: 121, left:0, right:0, backgroundColor:'#ffffff'}}>
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      variant="scrollable"
                      scrollButtons
                      allowScrollButtonsMobile
                      aria-label="scrollable auto tabs"
                    >
                      <Tab label="(유)일신물산" value="1"/>
                      <Tab label="삼정물산" value="2"/>
                    </Tabs>
                    <Divider />
                </div>
                    <TabPanel value="1">
                    <Box sx={{ display: 'flex', maxWidth: '100%', justifyContent: 'space-between', alignItems: 'flex-start', marginTop:'20px'}}>
                            <div><IconButton onClick={() => handlePhone('010-333-4444')}><PhoneAndroidIcon sx={{fontSize:30}} /></IconButton> 담당사원</div>
                            <div><IconButton onClick={() => handlePhone('065-712-9999')}><PhoneInTalkIcon sx={{fontSize:30}} /></IconButton> (유)일신물산</div>
                        </Box>
                        <Divider />
                        <Box sx={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', alignItems: 'center', marginTop:'20px', flexWrap: 'wrap'}}>
                            <div style={{fontWeight:'bold', fontSize:'18pt'}}>주류 공급내역 거래장 보기</div> 
                        </Box>
                        <Box sx={{ display: 'flex', maxWidth: '95%', justifyContent: 'center', alignItems: 'center', textAlign:'left', padding: '10px', marginTop:'20px'}}>
                            <Grid container>
                                <Grid xs={12} style={{fontWeight: 'bold'}}>2022년 12월 1일 (금)</Grid>
                                <Grid xs={7}>카스 500ml 유흥</Grid><Grid xs={2}>2 박스</Grid><Grid xs={3}></Grid>
                                <Grid xs={7}>참이슬 330ml 유흥</Grid><Grid xs={2}>3 박스</Grid><Grid xs={3}></Grid>
                                <Grid xs={7}>한라산 17 330ml 유흥</Grid><Grid xs={2}>1 박스</Grid><Grid xs={3} style={{textAlign:'right', color: 'red'}}>195,600원</Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box sx={{ display: 'flex', maxWidth: '95%', justifyContent: 'center', alignItems: 'center', textAlign:'left', padding: '10px'}}>
                            <Grid container>
                                <Grid xs={7}>결제</Grid><Grid xs={2}></Grid><Grid xs={3} style={{textAlign:'right', color: 'blue'}}>160,000원</Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box sx={{ display: 'flex', maxWidth: '95%', justifyContent: 'center', alignItems: 'center', textAlign:'left', padding: '10px'}}>
                            <Grid container>
                                <Grid xs={7}>잔액</Grid><Grid xs={2}></Grid><Grid xs={3} style={{textAlign:'right', color: 'red'}}>35,600원</Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box sx={{ display: 'flex', maxWidth: '95%', justifyContent: 'center', alignItems: 'center', textAlign:'left', padding: '10px' }}>
                            <Grid container>
                                <Grid xs={12} style={{fontWeight: 'bold'}}>2022년 12월 8일 (월)</Grid>
                                <Grid xs={7}>참이슬 330ml 유흥</Grid><Grid xs={2}>3 박스</Grid><Grid xs={3}></Grid>
                                <Grid xs={7}>한라산 17 330ml 유흥</Grid><Grid xs={2}>1 박스</Grid><Grid xs={3} style={{textAlign:'right', color: 'red'}}>160,000원</Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box sx={{ display: 'flex', maxWidth: '95%', justifyContent: 'center', alignItems: 'center', textAlign:'left', padding: '10px'}}>
                            <Grid container>
                                <Grid xs={7}>결제</Grid><Grid xs={2}></Grid><Grid xs={3} style={{textAlign:'right', color: 'blue'}}>170,000원</Grid>
                            </Grid>
                        </Box>
                        <Divider />
                        <Box sx={{ display: 'flex', maxWidth: '95%', justifyContent: 'center', alignItems: 'center', textAlign:'left', padding: '10px'}}>
                            <Grid container>
                                <Grid xs={7}>잔액</Grid><Grid xs={2}></Grid><Grid xs={3} style={{textAlign:'right', color: 'red'}}>25,600원</Grid>
                            </Grid>
                        </Box>
                        <Divider style={{borderWidth:'2px'}}/>
                        <Box sx={{ display: 'flex', maxWidth: '95%', justifyContent: 'center', alignItems: 'center', textAlign:'left', padding: '10px'}}>
                            <Grid container>
                                <Grid xs={7}>현재잔액</Grid><Grid xs={2}></Grid><Grid xs={3} style={{textAlign:'right', color: 'red'}}>25,600원</Grid>
                            </Grid>
                        </Box>
                    </TabPanel>
                    <TabPanel value="2">
                    </TabPanel>
                </TabContext>
            </Box>
        </div>
    );
}
export default AccountBook;