/******************************************************************
 * 
 *   baccus 장바구니 모듈
 *   2022.10.01 ~ 2022.10.21
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 ******************************************************************/

import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';

import DeleteIcon from '@mui/icons-material/Delete';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

import QTYCounter from '../common-lib/quantity';
import ConfirmDialog from '../common-lib/ConfirmDialog';

import { CompanyNameRedefine } from '../common-lib/companyNameRedefine';  // 회사이름을 단축해서 보여준다.(지점명 제거)
import { apiCall } from '../common-lib/custom-axios';

import { useRecoilState } from 'recoil';
import { userState } from '../store/user-store';

function ShoppingBag() {

    const [ { userId, userName, shopName, shopCode, shopNumber }, setUserState ] = useRecoilState(userState);

    const [ value, setValue ] = useState('ShoppingBag');
    const [ shoppingList, setShoppingList ] = useState((sessionStorage.getItem("shoppingList")) ? JSON.parse(sessionStorage.getItem("shoppingList")) : []);
    const [ shoppingList2, setShoppingList2 ] = useState((sessionStorage.getItem("shoppingList2")) ? JSON.parse(sessionStorage.getItem("shoppingList2")) : []);
    const [ open, setOpen ] = useState(false);  // dialog Y or N Confirm
    const [ delGoods, setDelGoods ] = useState('');
    const [ dialogMessage, setDialogMessage ] = useState('');
    const [ confirm, setConfirm ] = useState('cancel');
    const [ currentId, setCurrentId ] = useState('');

    const [ shippingList, setShippingList ] = useState([]);

    useEffect(() => {
      getShippingList();
    }, [value])

    useEffect(() => {
      sessionStorage.setItem("shoppingList", JSON.stringify(shoppingList));

      // card List 를 생성하도록 한다.
    }, [shoppingList])

    useEffect(() => {
      sessionStorage.setItem("shoppingList2", JSON.stringify(shoppingList2));

      // card List 생성
    }, [shoppingList2])

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const phoneHandler = () => {
      window.location.href = 'tel:010-7777-7777';
    }

    // 선택한 상품 삭제
    const onRemove = () => {
      setShoppingList(shoppingList.filter(goods => goods.goodsCode !== currentId));
      setShoppingList2(shoppingList2.filter(goods => goods.id !== currentId));
    }

    // 전체삭제
    const allRemove = () => {
      setShoppingList([]);
      setShoppingList2([]);

      // 세션스토리지를 clear 하면 SSID 값도 날아간다. ㅠ.ㅠ;
      //sessionStorage.clear();
    }

    const ConfirmMode = (id) => {

       setDelGoods(id);

      if( id === 'save' ) {
        setDialogMessage('배송을 요청 하시겠습니까 ?')
      }
      else if( id === 'delOne') {
        setDialogMessage('선택하신 제품을 삭제하시겠습니까 ?')
      }
      else if( id === 'delAll') {
        setDialogMessage('모든 선택 제품을 삭제하시겠습니까 ?')
      }

    }

    const selectId = (idx) => {
      setCurrentId(idx);
    }

    const onConfirm = () => {
      if( delGoods === 'save' ) {
          registerShipping();
      }
      else if( delGoods === 'delOne') {
        onRemove();
      }
      else if( delGoods === 'delAll') {
        allRemove();
      }
    }

    const updateQty = (idx, value) => {
      if(value === 0) {
        value = 1;
      }
      console.log('인덱스', idx, value, ' 레코드의 수량이 변경되었습니다.');

      // 요기서 배열의 값을 변경해준다 +_+!;
      // const 변수인데도 스프레드로 변수를 바꿀수 있었다.
      shoppingList[idx] = { ...shoppingList[idx], quantity : value};

      console.log(shoppingList[idx]);
    }

    const registerShipping = async () => {

//      if(shoppingList.length > 0) {
        const res = await apiCall.post('/shipping', {shoppingList});
//      }
//      if(shoppingList2.length > 0) {
        const res2 = await apiCall.post('/shipping2', {shoppingList2});
//      }

      // 서버에서는 트랜잭션으로 입력한다. 
      // console.log('배송저장', res);
      // console.log('그외요청', res2.data);

      if(res && res2) {
        if(res.status === 200 && res.statusText === 'OK' && res2.status === 200 && res2.statusText === 'OK') {


//          console.log(res.data);
          // 성공하면 장바구니를 비워줘야 한다.
          // 성공 메세지도 내보내준다.
          allRemove();
        }
      }
    }

    const getShippingList = async () => {
      const res = await apiCall.get('/shipping?status=예정')
      setShippingList(res.data);
      const res2 = await apiCall.get('/shipping2?status=예정')
      //setShippingList2(res2.data);
      console.log(res.data);
    }
    
    return (
        <div className="shoppingbag">
            <Box sx={{ maxWidth: { xs: '100vw', sm: '100vw' }, bgcolor: '#f6f6f6' }} style={{marginTop: 150}}>
            <TabContext value={value}>
            <div style={{display: 'inline-block', position: 'fixed', zIndex: 999, top: 121, left:0, right:0, backgroundColor:'#ffffff'}}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable auto tabs"
              >
                <Tab label="장바구니" value="ShoppingBag"/>
                <Tab label="배송예정" value="ShippingScheduled"/>
                <Tab label="배송확인" value="ShippingCompleted"/>
              </Tabs>
              <Divider style={{marginBottom: 0}} />
            </div>
                <TabPanel value="ShoppingBag">
                    <div style={{color:'#FF4D00', fontWeight: 'bold', fontSize: '14pt'}}>배송 예정일 2022년 9월 27일 (금) 입니다.</div>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', justifyContent: 'center', alignItems: 'center' , bgcolor: '#f6f6f6'}}>

                    {
                      ( shoppingList.length === 0 && shoppingList2.length === 0 ) &&  (
                        //  빈카드를 보여준다.
                          <>
                    <Box style={{display:'flex', top:0, left:0, right:0, margin:10}}>
                    <Card variant="outlined" className='order-card' style={{display: 'flex', width:'100%', borderRadius:10, justifyContent: 'center', alignItems: 'center' }}>
                      <CardContent>
                                <ErrorOutlineIcon className='ShoppingBag-Icon' sx={{fontSize:80}} />
                                <div style={{color:'#FF4D00', fontWeight: 'bold', fontSize: '14pt'}}>장바구니에 담긴 상품이 없습니다.</div>
                      </CardContent>
                    </Card>
                    </Box>
                          </>
                      )
                    }

                    { 
                    (shoppingList.length !== 0) &&
                        shoppingList.map((goods, idx) => {
                          return (
                            <>
                                {/* 주문할 쇼핑 리스트 시작 */}
                                <Box style={{display:'flex', top:0, left:0, right:0, margin:4, padding:4, justifyContent: 'flex-start' }} >
                                <Card variant="outlined" className='order-card' style={{width:'100%', borderRadius:10}}>
                                  <div>
                                    <div style={{ fontSize:'20px', fontWeight:'bold', textAlign:'left', marginLeft:20, marginRight:20, marginTop: 10, marginBottom:0 }}>{goods.goodsName}</div>
                                    <div style={{ textAlign:'left', fontSize: 14, color:'#999999', marginLeft:20 }}>
                                                  {CompanyNameRedefine(goods.companyName)} / 1박스 {goods.bottlePerBox}병 / {goods.volume}ml / {goods.alcoholRate}도</div>
                                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'center', width:'100%' }}>
                                      <div style={{ width:'50%' }}>
                                        <CardMedia component='img' image={goods.imageFilename ? '/sample-goods/' + goods.imageFilename : '/sample-goods/noImage.png'} style={{width:'150px', height: '150px'}} className='goods-image' />
                                      </div>
                                      <div style={{ display:'flex', flexWrap:'wrap', width:'35%', justifyContent:'center', alignItems:'center', marginTop: 0 }}>
                                        <input value='박스 단위' style={{ border:'1px solid #cfcfcf', fontSize:14, 
                                                                          height: 25 , marginTop:5, marginBottom:5, width: '86px', borderRadius: 4, textAlign:'center' }} />
                                        <QTYCounter defaultValue={Number(shoppingList[idx].quantity)} updateQty={updateQty} idx={idx} />
                                        <div style={{ display:'flex', justifyContent:'center', alignItems:'right', marginTop:10, marginRight: 10, marginBottom: 10 }} >
                                          <IconButton sx={{ bgcolor:'#efefef' }} onClick={() => {ConfirmMode('delOne'); selectId(goods.goodsCode); setOpen(true)}}>
                                            <DeleteIcon className='ShoppingBagIcon-Icon' sx={{fontSize:40, color:'#FF4D00'}} />
                                          </IconButton>
                                        </div>
                                    </div>
                                    </div>
                                  </div>
                                </Card>
                                </Box>
                                {/* 주문할 쇼핑 리스트 끝 */} 
                            </>
                          )
                        }
                    )}

                    { 
                    (shoppingList2.length !== 0) &&
                        shoppingList2.map((goods, idx) => {
                          return (
                            <>
                                {/* 그외 주문 리스트 시작 */}
                                <Box style={{display:'flex', top:0, left:0, right:0, margin:4, padding:4, justifyContent: 'flex-start' }} >
                                <Card variant="outlined" className='order-card' style={{width:'100%', borderRadius:10}}>
                                  <div>
                                  <div style={{ fontSize:'20px', fontWeight:'bold', textAlign:'left', marginLeft:20, marginRight:20, marginTop: 10, marginBottom:0 }}>{goods.orderTitle}</div>
                              <div style={{ display:'flex',alignItems:'center', justifyContent: 'center', width:'100%' }}>
                                <div style={{ width:'50%' }}>
                                  <CardMedia component='img' image={goods.imgUrl ? '/sample-goods/' + goods.imgUrl : '/sample-goods/noImage.png'} style={{width:'150px', height: '150px'}} className='etc-image' />
                                </div>
                                <div style={{ display:'flex', flexWrap:'wrap', width:'35%', justifyContent:'center', alignItems:'center', marginTop: 0 }}>
                                  <div style={{ display:'flex', justifyContent:'center', alignItems:'right', marginTop:10, marginRight: 10, marginBottom:6 }} >
                                          <IconButton sx={{ bgcolor:'#efefef' }} onClick={() => {ConfirmMode('delOne'); selectId(goods.id); setOpen(true)}}>
                                            <DeleteIcon className='ShoppingBagIcon-Icon' sx={{fontSize:40, color:'#FF4D00'}} />
                                          </IconButton>
                                  </div>
                              </div>
                              </div>
                                  </div>
                                </Card>
                                </Box>
                                {/* 그외 주문 리스트 끝 */} 
                            </>
                          )
                        }
                    )}

                    <Paper sx={{ position: 'fixed', bottom:0, height: 50, left: 0, right: 0,
                                 zIndex: 99987, backgroundColor: '#FF4D00', color: 'white', borderRadius:0,
                                 display: 'flex', justifyContent:'center', alignItems: 'center' }} elevation={0} className='orderBar'>
                      <Button variant='text' sx={{left:0, width:'50%', height:'50', color: 'white', fontWeight: 'bold'}} onClick={() => { ConfirmMode('delAll'); setOpen(true);}}>모두 지우기</Button>
                      <Divider orientation="vertical" flexItem sx={{backgroundColor:'white'}} />
                      <Button variant='text' sx={{right:0, width:'50%', height:'50', color: 'white', fontWeight: 'bold'}} onClick={() => { ConfirmMode('save'); setOpen(true); }}>배송요청하기</Button>
                    </Paper>
                    </Box>
                    <Box sx={{ display: 'flex', maxWidth: '100%', justifyContent: 'center', alignItems: 'center' , bgcolor: '#f6f6f6', height: '50px'}}>
                    </Box>
                </TabPanel>
                <TabPanel value="ShippingScheduled" style={{ minHeight: 'calc(~"100vh - 130px")'}}>
                <Box sx={{ display: 'inline-flex', flexWrap: 'wrap', maxWidth: '100%', justifyContent: 'center', alignItems: 'center' , bgcolor: '#f6f6f6'}}>

                {
                      ( shippingList.length === 0 ) &&  (
                        //  빈카드를 보여준다.
                          <>
                    <Box style={{display:'flex', top:0, left:0, right:0, margin:10}}>
                    <Card variant="outlined" className='order-card' style={{display: 'flex', width:'100%', borderRadius:10, justifyContent: 'center', alignItems: 'center' }}>
                      <CardContent>
                                <ErrorOutlineIcon className='ShoppingBag-Icon' sx={{fontSize:80}} />
                                <div style={{color:'#FF4D00', fontWeight: 'bold', fontSize: '14pt'}}>배송예정인 상품이 없습니다.</div>
                      </CardContent>
                    </Card>
                    </Box>
                          </>
                      )
                }

                { 
                    (shippingList.length !== 0) &&
                        shippingList.map((goods, idx) => {
                          return (
                            <>
                                {/* 주문할 쇼핑 리스트 시작 */}
                                <Box style={{display:'flex', top:0, left:0, right:0, margin:4, padding:4, justifyContent: 'flex-start' }} >
                                <Card variant="outlined" className='order-card' style={{width:'100%', borderRadius:10}}>
                                  <div>
                                    <div style={{ fontSize:'20px', fontWeight:'bold', textAlign:'left', marginLeft:20, marginRight:20, marginTop: 10, marginBottom:0 }}>{goods.goodsName}</div>
                                    <div style={{ textAlign:'left', fontSize: 14, color:'#999999', marginLeft:20 }}>
                                          {CompanyNameRedefine(goods.companyName)} / 1박스 {goods.bottlePerBox}병 / {goods.volume}ml / {goods.alcoholRate}도</div>
                                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'center', width:'100%' }}>
                                      <div style={{ width:'50%' }}>
                                        <CardMedia component='img' image={goods.imageFilename ? '/sample-goods/' + goods.imageFilename : '/sample-goods/noImage.png'} style={{width:'150px', height: '150px'}} className='goods-image' />
                                      </div>
                                      {/* <div style={{ display:'flex', flexWrap:'wrap', width:'35%', justifyContent:'center', alignItems:'center', marginTop: 0 }}>
                                        <input value='박스 단위' style={{ border:'1px solid #cfcfcf', fontSize:14, 
                                                                          height: 25 , marginTop:5, marginBottom:5, width: '86px', borderRadius: 4, textAlign:'center' }} />
                                        <QTYCounter defaultValue={Number(shoppingList[idx].quantity)} updateQty={updateQty} idx={idx} />
                                        <div style={{ display:'flex', justifyContent:'center', alignItems:'right', marginTop:10, marginRight: 10, marginBottom: 10 }} >
                                          <IconButton sx={{ bgcolor:'#efefef' }} onClick={() => {ConfirmMode('delOne'); selectId(goods.goodsCode); setOpen(true)}}>
                                            <DeleteIcon className='ShoppingBagIcon-Icon' sx={{fontSize:40, color:'#FF4D00'}} />
                                          </IconButton>
                                        </div>
                                    </div> */}
                                    </div>
                                  </div>
                                </Card>
                                </Box>
                                {/* 주문할 쇼핑 리스트 끝 */} 
                            </>
                          )
                        }
                    )}

                  </Box>
                </TabPanel>
                <TabPanel value="ShippingCompleted">
                  <div style={{color:'#FF4D00', fontWeight: 'bold', fontSize: '14pt'}}>배송완료 : 2022년 9월 27일 (금)</div>
                  <div style={{color:'#FF4D00', fontWeight: 'bold', fontSize: '14pt'}}>배송완료 하였습니다. 주문하신 상품과 맞지 않거나 문의사항이 있으신 경우 하단의 전화 부탁드립니다.</div>
                    <Box style={{position: 'relative', display:'flex', top:0, left:0, right:0, margin:10}}>
                        <Card variant="outlined" style={{display: 'flex', width:500, borderRadius:10, margin:'auto' }}>
                            <CardMedia component='img' image='/sample-goods/photo_2022-10-07_01-27-52.jpg' style={{}} className='goods-image'  sx={{width:500}} />
                        </Card>
                    </Box>
                    <div style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                      <div><IconButton onClick={phoneHandler}><PhoneAndroidIcon sx={{fontSize:40}} /></IconButton> 담당사원 전화걸기</div>
                    </div>
                </TabPanel>
                </TabContext>
            </Box>        

            <ConfirmDialog title="" open={open} setOpen={setOpen} onConfirm={onConfirm} >{dialogMessage}</ConfirmDialog>

            {/* <Dialog open={open} onClose={() => setOpen(false)} id="alert-dialog-title">
                     <DialogContent>
                         <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
                     </DialogContent>
                     <DialogActions sx={{ justifyContent: "center" }}>
                        <Button variant="outlined" onClick={() => {setConfirm('cancel'); setOpen(false)}}>취 소</Button>
                        <Button variant="contained" onClick={() => {setOpen(false); setCommand('ok');}} elevation={0}>확 인</Button>
                     </DialogActions>
              </Dialog> */}
        </div>
    );
}
export default ShoppingBag;