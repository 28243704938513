import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import EastIcon from '@mui/icons-material/East';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CampaignIcon from '@mui/icons-material/Campaign';

function TotalMenu(props) {

    console.log(props)

    return (
        <>
            <Box style={{ boxSizing: 'border-box', display:'flex', position: 'absolute', width: '100%', top:0, left:0, height: 150, backgroundColor:'#7B0C8D', justifyContent:'flex-start',
                        alignItems: 'center', padding: '30px'}} >
                <div><IconButton onClick={() => props.onToggleDrawer()}
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                >
                <EastIcon style={{color: '#ffffff'}} /></IconButton></div>
                <div style={{color:'#ffffff', fontSize:'20pt'}}>서비스 전체 보기</div>
                <div><img src='baccus64.png' /></div>
            </Box>
            <Box style={{ boxSizing: 'border-box', padding: '30px', position: 'relative', marginTop: '130px'}}>
              <Box style={{ fontWeight: 'bold', fontSize: '18pt'}}>
                  <ListAltIcon sx={{fontSize:'22pt', verticalAlign: 'middle'}} />
                  &nbsp; 공지사항 및 이벤트 보기
                  <hr style={{ border:'none', borderTop: '1px dotted #efefef', borderWidth: '4px', }} />
                  <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <CampaignIcon style={{color: 'red'}} /><Typography> 바쿠스 공지사항</Typography> &nbsp;&nbsp;&nbsp;
                  <CampaignIcon style={{color: 'red'}} /><Typography> 거래처 공지사항</Typography>
                  </div>
              </Box>
              <Box style={{ fontWeight: 'bold', fontSize: '18pt', marginTop: '30px'}}>
                  <ListAltIcon sx={{fontSize:'20pt', verticalAlign: 'middle'}} />
                  &nbsp; 주문 상품 선택
                  <hr style={{ border:'none', borderTop: '1px dotted #efefef', borderWidth: '4px', }} />
                  <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <CampaignIcon style={{color: 'red'}} /><Typography> 바쿠스 공지사항</Typography> &nbsp;&nbsp;&nbsp;
                  <CampaignIcon style={{color: 'red'}} /><Typography> 거래처 공지사항</Typography>
                  </div>
                  <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <CampaignIcon style={{color: 'red'}} /><Typography> 바쿠스 공지사항</Typography> &nbsp;&nbsp;&nbsp;
                  <CampaignIcon style={{color: 'red'}} /><Typography> 거래처 공지사항</Typography>
                  </div>
                  <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <CampaignIcon style={{color: 'red'}} /><Typography> 바쿠스 공지사항</Typography> &nbsp;&nbsp;&nbsp;
                  <CampaignIcon style={{color: 'red'}} /><Typography> 거래처 공지사항</Typography>
                  </div>
              </Box>
              <Box style={{ fontWeight: 'bold', fontSize: '18pt', marginTop: '30px'}}>
                  <ListAltIcon sx={{fontSize:'20pt', verticalAlign: 'middle'}} />
                  &nbsp; 장바구니 관리
                  <hr style={{ border:'none', borderTop: '1px dotted #efefef', borderWidth: '4px', }} />
                  <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <CampaignIcon style={{color: 'red'}} /><Typography> 바쿠스 공지사항</Typography> &nbsp;&nbsp;&nbsp;
                  <CampaignIcon style={{color: 'red'}} /><Typography> 거래처 공지사항</Typography>
                  </div>
              </Box>
              <Box style={{ fontWeight: 'bold', fontSize: '18pt', marginTop: '30px'}}>
                  <ListAltIcon sx={{fontSize:'20pt', verticalAlign: 'middle'}} />
                  &nbsp; 거래장 확인
                  <hr style={{ border:'none', borderTop: '1px dotted #efefef', borderWidth: '4px', }} />
                  <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <CampaignIcon style={{color: 'red'}} /><Typography> 바쿠스 공지사항</Typography> &nbsp;&nbsp;&nbsp;
                  <CampaignIcon style={{color: 'red'}} /><Typography> 거래처 공지사항</Typography>
                  </div>
              </Box>
              <Box style={{ fontWeight: 'bold', fontSize: '18pt', marginTop: '30px'}}>
                  <ListAltIcon sx={{fontSize:'20pt', verticalAlign: 'middle'}} />
                  &nbsp; 설정하기
                  <hr style={{ border:'none', borderTop: '1px dotted #efefef', borderWidth: '4px', }} />
                  <div style={{ display:'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
                  <CampaignIcon style={{color: 'red'}} /><Typography> 바쿠스 공지사항</Typography> &nbsp;&nbsp;&nbsp;
                  <CampaignIcon style={{color: 'red'}} /><Typography> 거래처 공지사항</Typography>
                  </div>
              </Box>
            </Box>
        </>
    );
}

export default TotalMenu;