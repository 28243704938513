/******************************************************************
 * 
 *   baccus 발주처 주문 모듈
 *   2022.10.01 ~ 2022.10.27
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

import React, { useState, useEffect } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

// import AlarmIcon from '@mui/icons-material/Alarm';
// import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';

import QTYCounter from '../common-lib/quantity';
import '../css/ordersheet.css';
import { SnackbarContent } from '@mui/material';

import { apiCall } from '../common-lib/custom-axios';

import { useRecoilState } from 'recoil';
import { userState } from '../store/user-store';

import { CompanyNameRedefine } from '../common-lib/companyNameRedefine';
import ScrollToTop from '../ScrollReset';

import { GOODS_IMAGES_URL } from '../config/config';


function OrderSheet() {

    const [value, setValue] = useState('Favorite');
    const [unit, setUnit] = useState('box');

    const [ snackbarOpen, setSnackBarOpen ] = useState(false);      // snackbar
    const [ open, setOpen ] = useState(false);                      // dialog 창
    const [ searchStr, setSearchStr ] = useState('');

    const [ clickedColor, setClickedColor ] = useState([]);

    const [ { userId, userName, shopName, shopCode, shopNumber, classification }, setUserState ] = useRecoilState(userState);

    const [ goodsList, setGoodsList ] = useState([]); 
    const [ etcList, setEtcList ] = useState([]); 
    const [ searchGoods, setSearchGoods ] = useState([]); 

    const [ maker, setMaker ] = useState([]);

    const [ shoppingList, setShoppingList ] = useState((sessionStorage.getItem("shoppingList")) ? JSON.parse(sessionStorage.getItem("shoppingList")) : []);
    const [ shoppingList2, setshoppingList2 ] = useState((sessionStorage.getItem("shoppingList2")) ? JSON.parse(sessionStorage.getItem("shoppingList2")) : []);
        
    useEffect(() => {

      // 만약 장바구니에 상품이 있다면...빨간색으로 만들어 준다.
      getRecentGoodsList();  // 최근 6개월간 주문했던 상품을 기준으로 보여줌.
      getEtcList();
    }, [])

    useEffect(() => {
        // 상품이 변경되면 새로 기록한다.
        sessionStorage.setItem("shoppingList", JSON.stringify(shoppingList));
      }, [shoppingList])

    useEffect(() => {
      sessionStorage.setItem("shoppingList2", JSON.stringify(shoppingList2));
    }, [shoppingList2])

    // QTYCounter 처럼 컴포넌트로 만들어서 돌린다.
    const bagButton = (value, idx) => {
      return (
        <IconButton sx={{bgcolor:'#efefef'}} onClick={(e) => addOrderList(value[idx], idx)}>
          <ShoppingBagIcon className='ShoppingBagIcon-Icon' sx={{fontSize:40}} color={value[idx].button === 'clicked' ? 'error': 'default'} />
        </IconButton>
    )};

    // useEffect(() => {
    //   console.log(goodsList)
    // }, [goodsList])

    // useEffect(() => {
    //   console.log(etcList)
    // }, [etcList])

    // 컴포넌트에서 받아온 값으로 수량을 설정한다.

    const getRecentGoodsList = async () => {
      const res = await apiCall.get('/recentgoods', {params: {shopCode : shopCode}}).then( res => res.data);

      let quan = 1;
      res.map((goods, idx) => {
        res[idx] = { ...res[idx], quantity: '1'};
        res[idx] = { ...res[idx], button: ''};
        res[idx] = { ...res[idx], shopCode: shopCode};
      })

      setGoodsList(res);
    }

    const getEtcList = async () => {
      const res = await apiCall.get('/etclist')

      setEtcList(res.data);
    }

    const updateQty = (idx, value) => {

      if(value < 1) {
        value = 1;
      }

      goodsList[idx] = { ...goodsList[idx], quantity : value};
    }

    const addOrderList = (values, idx) => {

      let chkSameCode = false;

      goodsList[idx] = { ...values, button: 'clicked'};

      shoppingList.map((goods, index) => {
        if(goods.goodsCode === values.goodsCode) {
          chkSameCode = true;
          let newQuantity = Number(goods.quantity) + Number(values.quantity);
          shoppingList[index] = {...goods, quantity : newQuantity }
          shoppingList[index] = {...goods, button: 'clicked' }
          sessionStorage.setItem("shoppingList", JSON.stringify(shoppingList));
        }
      })

      if(values.quantity !== 0 && !chkSameCode) {
        // 수량이 0이 아닐때만 장바구니에 넣을수 있다.
        // 동일한 상품이 들어왔을때 수량 + 기능이 있어야 한다.
        // console.log('장바구니로 보내기가 눌렸습니다.');
        setShoppingList([...shoppingList, values]);
        // 상품을 넣은후에 하단에 snackbar 메세지 출력
        // 여기서 세션을 저장하면 가상돔 때문에 마지막 레코드 저장안됨
        // 가져올때는 아래와 같이 가져온다.
        //const shoppingList = JSON.parse(sessionStorage.getItem("shoppingList"));
        setSnackBarOpen(true);
      }
      else if(values.quantity !== 0 && chkSameCode) {
        chkSameCode = false;
      }
      else {
        // 상품의 수량이 0이면 메세지 출력
        setOpen(true);
      }
    }

    const addOrderList2 = (values, idx) => {

      let chkSameCode = false;

      etcList[idx] = { ...values, button: 'clicked'};

      shoppingList2.map((etcs, index) => {
        console.log(etcs);
        if(etcs.id === values.id) {
          chkSameCode = true;
          shoppingList2[index] = {...etcs, button: 'clicked' }
          sessionStorage.setItem("shoppingList2", JSON.stringify(shoppingList2));
        }
      })

      if(!chkSameCode) {
        setshoppingList2([...shoppingList2, values]);
        setSnackBarOpen(true);
      }
      else if(chkSameCode) {
        chkSameCode = false;
      }
    }
    
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const goodsHandleChange = (event) => {
        setUnit(event.target.value);
    };

    const snackbarClose = () => {
       setSnackBarOpen(false)  ;
    }

    const handleDialogClose = () => {
      setOpen(false);
    }

    const getGoodsSearch = async () => {
      console.log(searchStr)
      // 상품 검색 api call
      const res = await apiCall.get(`/searchgoods?q=${searchStr}&status=${classification}`);

      console.log(res.data);
      setSearchGoods(res.data);
    }

    return (
        <div className="ordersheet" style={{margin:0}}>
            <TabContext value={value}>
            <Box style={{display: 'inline-block', position: 'fixed', zIndex: 999, top: 121, left:0, right:0, backgroundColor:'#ffffff'}}>
              <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                //allowScrollButtonsMobile
                aria-label="scrollable auto tabs"
              >
                <Tab label="자주찾는상품" value="Favorite"/>
                <Tab label="그 외 요청" value="etc"/>
                {/* <Tab label="맞춤배송" value="RegularDelivery"/> */}
                <Tab label="상품찾기" value="GoodsSearch"/>
              </Tabs>
              <Divider style={{marginBottom: 0}} />
            </Box>
                <TabPanel value="Favorite" sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', marginTop: 20, justifyContent: 'center', alignItems: 'center' , bgcolor: '#f6f6f6' }}>
                <ScrollToTop />
                  <Box className='order-zone' sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems: 'center' , bgcolor: '#f6f6f6' }}>

                    {goodsList.map((goods, idx) => {

                      return (
                        <>
                            {/* 상품 리스트 시작 */}
                            <Box style={{display:'flex', top:0, left:0, right:0, margin:4, padding:4, justifyContent: 'flex-start' }} >
                                <Card variant="outlined" className='order-card' style={{width:'100%', borderRadius:10}}>
                                  <div>
                                    <div style={{ fontSize:'20px', fontWeight:'bold', textAlign:'left', marginLeft:20, marginRight:20, marginTop: 10, marginBottom:0 }}>{goods.goodsName}</div>
                                    <div style={{ textAlign:'left', fontSize: 14, color:'#999999', marginLeft:20 }}>
                                                   {CompanyNameRedefine(goods.companyName)} /
                                                   1박스 {goods.bottlePerBox}병 / {goods.volume}ml / 
                                                   {goods.alcoholRate}도
                                    </div>
                                    <div style={{ display:'flex',alignItems:'center', justifyContent: 'center', width:'100%' }}>
                                      <div style={{ width:'50%' }}>
                                        <CardMedia component='img' image={goods.imageFilename ? GOODS_IMAGES_URL + '/' + goods.imageFilename : GOODS_IMAGES_URL + '/noImage.png'} style={{width:'150px', height: '150px', objectFit:'contain'}} className='goods-image' />
                                      </div>
                                      <div style={{ display:'flex', flexWrap:'wrap', width:'35%', justifyContent:'center', alignItems:'center', marginTop: 0 }}>
                                        <select className='BoxOrBottle' style={{ border:'1px solid #cfcfcf', fontSize:14, boxShadow: 'none',
                                                                          height: 28 , marginLeft: 8, marginTop:5, marginBottom:8, width: '92px', borderRadius: 4, textAlign:'center' }} 
                                          onChange={goodsHandleChange}
                                          defaultValue='box'
                                        >
                                          <option value='box'    selected>박스</option>
                                          <option value='bottle' disabled >낱병</option> 
                                        </select>
                                        <QTYCounter defaultValue={Number(goodsList[idx].quantity)} updateQty={updateQty} idx={idx} />
                                        <div style={{ display:'flex', justifyContent:'center', alignItems:'right', marginTop:10, marginRight: 10, marginBottom:6 }} >
                                          <IconButton sx={{bgcolor:'#efefef'}} onClick={(e) => addOrderList(goodsList[idx], idx)}>
                                            <ShoppingBagIcon className='ShoppingBagIcon-Icon' sx={{fontSize:40}} color={goodsList[idx].button === 'clicked' ? 'error': 'default'} />
                                          </IconButton>
                                        </div>
                                    </div>
                                    </div>
                                  </div>
                                </Card>
                            </Box>
                            {/* 상품 리스트 끝 */} 
                        </>
                      )
                    })}
                  </Box>
                </TabPanel>
                
                <TabPanel value="etc" sx={{ display: 'flex', flexWrap: 'wrap', maxWidth: '100%', marginTop: -6, justifyContent: 'center', alignItems: 'center' , bgcolor: '#f6f6f6' }}>
                <ScrollToTop />
                  <Box className='order-zone' sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems: 'center' , bgcolor: '#f6f6f6' }}>

                    {etcList.map((etcs, idx) => {

                      return (
                        <>
                          {/* 그 외 요청 시작 */}
                          <Box style={{display:'flex', top:0, left:0, right:0, margin:4, padding:4, justifyContent: 'flex-start' }} >
                            <Card variant="outlined" className='order-card' style={{width:'100%', borderRadius:10}}>
                            <div>
                              <div style={{ fontSize:'20px', fontWeight:'bold', textAlign:'left', marginLeft:20, marginRight:20, marginTop: 10, marginBottom:0 }}>{etcs.orderTitle}</div>
                              <div style={{ display:'flex',alignItems:'center', justifyContent: 'center', width:'100%' }}>
                                <div style={{ width:'50%' }}>
                                  <CardMedia component='img' image={etcs.imgUrl ? GOODS_IMAGES_URL + '/' + etcs.imgUrl : GOODS_IMAGES_URL + '/noImage.png'} style={{width:'150px', height: '150px'}} className='etc-image' />
                                </div>
                                <div style={{ display:'flex', flexWrap:'wrap', width:'35%', justifyContent:'center', alignItems:'center', marginTop: 0 }}>
                                  <div style={{ display:'flex', justifyContent:'center', alignItems:'right', marginTop:10, marginRight: 10, marginBottom:6 }} >
                                    <IconButton sx={{bgcolor:'#efefef'}} onClick={(e) => {console.log(idx); addOrderList2(etcList[idx], idx);}}>
                                      <ShoppingBagIcon className='ShoppingBagIcon-Icon' sx={{fontSize:40}} color={etcs.button === 'clicked' ? 'error': 'default'} />
                                    </IconButton>
                                  </div>
                              </div>
                              </div>
                            </div>
                            </Card>
                          </Box>
                          {/* 상품 리스트 끝 */} 
                        </>
                      )
                    })}
                  </Box>
                </TabPanel>

                {/* <TabPanel value="RegularDelivery">
                </TabPanel> */}
                <TabPanel value="GoodsSearch" sx={{ flex:1, display: 'flex', flexWrap: 'wrap', maxWidth: '100%', marginTop: -12, justifyContent: 'center', alignItems: 'center', bgcolor: '#f6f6f6' }}>
                <ScrollToTop />
                <Box sx={{ maxWidth: { xs: '100vw', sm: '100vw' }, bgcolor: '#f6f6f6' }} style={{marginTop: 160}}>
                </Box>
                    <Box sx={{ minWidth: 240, width:'100%' }}>
                        <TextField id="searchString" label="상품명 혹은 제조사 (일부가능)" variant="outlined" onChange={(e) => {setSearchStr(e.target.value);}} 
                                   onKeyPress={(e) => { if(e.key === 'Enter') {getGoodsSearch()}}} />
                        <Button variant="contained" sx={{height:55}} onClick={getGoodsSearch}>검 색</Button>
                    </Box>

                    <Box className='order-zone' sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'left', alignItems: 'center' , bgcolor: '#f6f6f6' }}>
                    
                    {searchStr && searchGoods.map((goods, idx) => {

return (
  <>
      {/* 상품 리스트 시작 */}
      <Box style={{display:'flex', top:0, left:0, right:0, margin:4, padding:4, justifyContent: 'flex-start' }} >
          <Card variant="outlined" className='order-card' style={{width:'100%', borderRadius:10}}>
            <div>
              <div style={{ fontSize:'20px', fontWeight:'bold', textAlign:'left', marginLeft:20, marginRight:20, marginTop: 10, marginBottom:0 }}>{goods.goodsName}</div>
              <div style={{ textAlign:'left', fontSize: 14, color:'#999999', marginLeft:20 }}>
                             {CompanyNameRedefine(goods.companyName)} /
                             1박스 {goods.bottlePerBox}병 / {goods.volume}ml / 
                             {goods.alcoholRate}도
              </div>
              <div style={{ display:'flex',alignItems:'center', justifyContent: 'center', width:'100%' }}>
                <div style={{ width:'50%' }}>
                  <CardMedia component='img' image={goods.imageFilename ? GOODS_IMAGES_URL + '/' + goods.imageFilename : GOODS_IMAGES_URL + '/noImage.png'} style={{width:'150px', height: '150px'}} className='goods-image' />
                </div>
                <div style={{ display:'flex', flexWrap:'wrap', width:'35%', justifyContent:'center', alignItems:'center', marginTop: 0 }}>
                  <select className='BoxOrBottle' style={{ border:'1px solid #cfcfcf', fontSize:14, boxShadow: 'none',
                                                    height: 28 , marginLeft: 8, marginTop:5, marginBottom:8, width: '92px', borderRadius: 4, textAlign:'center' }} 
                    onChange={goodsHandleChange}
                    defaultValue='box'
                  >
                    <option value='box'    selected>박스</option>
                    <option value='bottle' disabled >낱병</option> 
                  </select>
                  {/* <QTYCounter defaultValue={Number(goodsList[idx].quantity)} updateQty={updateQty} idx={idx} /> */}
                  <div style={{ display:'flex', justifyContent:'center', alignItems:'right', marginTop:10, marginRight: 10, marginBottom:6 }} >
                    <IconButton sx={{bgcolor:'#efefef'}} onClick={(e) => addOrderList(goodsList[idx], idx)}>
                      {/* <ShoppingBagIcon className='ShoppingBagIcon-Icon' sx={{fontSize:40}} color={goodsList[idx].button === 'clicked' ? 'error': 'default'} /> */}
                    </IconButton>
                  </div>
              </div>
              </div>
            </div>
          </Card>
      </Box>
      {/* 상품 리스트 끝 */} 
  </>
)
})}
</Box>
                </TabPanel>
            </TabContext>

            <Snackbar sx={{zIndex:999999}}
                      open={snackbarOpen}
                      autoHideDuration={2000}
                      onClose={snackbarClose}
                      anchorOrigin={{ vertical: 'bottom', horizontal:'center' }}
                      severity="error"
            >
              <SnackbarContent style={{backgroundColor:'#FF4D00', fontSize: 15}} message="장바구니에 상품이 추가되었습니다." />
            </Snackbar>

                 <Dialog open={open} onClose={handleDialogClose} id="alert-dialog-title">
                     <DialogContent>
                         <DialogContentText id="alert-dialog-description">주문 수량을 결정해주세요.</DialogContentText>
                     </DialogContent>
                     <DialogActions sx={{ justifyContent: "center" }}>
                       <Button variant="contained" onClick={handleDialogClose}>확 인</Button>
                     </DialogActions>
              </Dialog>
        </div>
    );
}
export default OrderSheet;