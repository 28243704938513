import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';

import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import Link from '@mui/material/Link';
import { yellow } from '@mui/material/colors';
import { styled } from '@mui/material/styles';

import { useRecoilState } from 'recoil';
import { loginState, userState } from '../store/user-store';
import { apiLogin } from '../common-lib/custom-axios';
import Cookies from 'universal-cookie';

import '../css/login.css';

const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(yellow[500]),
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
}));

const style = theme => ({
    multilineColor:{
        color:'red'
    }
});

function Login() {

    let navigate = useNavigate();

    const [ isLogin, setLoginState ] = useRecoilState(loginState);
    const [ { userId, userName, shopName, shopCode, shopNumber, classification }, setUserState ] = useRecoilState(userState);

    const cookies = new Cookies();
    const isChecked = (cookies.get('rememberChk') === 'true' ? true : false);

    console.log('isChecked', isChecked)

    const [ open, setOpen ] = useState(false);
    const [ goto, setGoto ] = useState(false);
    const [ dialogMessage, setDialogMessage ] = useState('');
    const [ dialogTitleText, setDialogTitleText ] = useState('');
    const [ remember, setRemember ] = useState(isChecked);
    const [ remMyId , setRemMyId ] = useState(cookies.get('myId') ? cookies.get('myId'): '');
    const [ remMyPw , setRemMyPw ] = useState(cookies.get('myPw') ? window.atob(cookies.get('myPw')): '');

    const [ winWith, setWinWith ] = useState(0);
    const [ winHeight, setWinHeight ] = useState(0);

    const topSize1 = winHeight - 40;
    const topSize2 = winHeight - 90;

    const centerValue = winWith / 2;
    const leftSizeJoin = centerValue + 60;
    const rightSizeFirst = leftSizeJoin - 100;

    const leftSizeMyPw = centerValue + 30;
    const rightSizeMyId = centerValue - 110;

    useEffect(() => {
        setWinWith(window.innerWidth);
        setWinHeight(window.innerHeight);
    }, [])


    useEffect(() => {
        if(goto === true) navigate('/');
    }, [goto]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const values = {
            email: data.get('email'),
            password: data.get('password'),
        };

        if(!data.get('email') || data.get('email') === '') {
            setDialogTitleText('');
            setDialogMessage('아이디는 반드시 입력해주세요');
            setOpen(true);
        }
        else if(!data.get('password') || data.get('password') === '') {
            setDialogTitleText('');
            setDialogMessage('비밀번호는 반드시 입력해주세요');
            setOpen(true);
        }
        else {
            const res = await apiLogin.post('/login', values ).then(res => res.data);
    
            console.log(res)
    
            if(res.loginSuccess) {
                // 성공하면 사용자 환경을 설정해준다.
                sessionStorage.setItem('SSID', res.SSID);

                // remember 체크인된 쿠키값이 true일때만 
                if(remember) {
                    cookies.set('myId', remMyId, {path: '/', maxAge: 60 * 60 * 24 * 365});
                    cookies.set('myPw', window.btoa(remMyPw), {path: '/', maxAge: 60 * 60 * 24 * 365});
                }
                else {
                    cookies.remove('myId');
                    cookies.remove('myPw');
                }

                console.log(res)
                    
                setLoginState({ isLogin: true });
                setUserState({ userId : res.userId, 
                               userName : res.userName, shopCode: res.shopCode, shopName: res.shopName, shopNumber: res.shopNumber, 
                               classification : res.classification,
                 });
                setGoto(true);
            }
            else {
                // 로그인 실패시에 모달창을 띄워서 메세지를 보여준다.
                setDialogTitleText(<DialogTitle>로그인 실패</DialogTitle>);
                setDialogMessage('아이디나 비밀번호가 틀렸습니다. 다시 입력해주세요.');
                setOpen(true);
            }
        }
    }

    const handleClose = () => {
        setOpen(false);
    }

    const rememberMe = (e) => {
        setRemember(e.target.checked)
        cookies.set('rememberChk', e.target.checked, {path: '/', maxAge: 60 * 60 * 24 * 365});
        // 쿠키설정
        if(e.target.checked) {
            cookies.set('myId', remMyId, {path: '/', maxAge: 60 * 60 * 24 * 365});
            cookies.set('myPw', window.btoa(remMyPw), {path: '/', maxAge: 60 * 60 * 24 * 365});
        }
        else {
            cookies.remove('myId');
            cookies.remove('myPw');
        }
    }

    const handleInput = (e) => {
        if(e.target.name === 'email')    setRemMyId(e.target.value);
        if(e.target.name === 'password') setRemMyPw(e.target.value);
    }

    return (
        <>
        <Box component="form" onSubmit={handleSubmit} noValidate >
            <div className="login" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: winWith, height: winHeight }}>
                <img src='baccus192.png' className='main-logo' />
                <div className='container-fluid'></div>
                <div className='baccus'>
                    바 쿠 스
                </div>
                <div className='baccus2'>
                    모바일 주문시스템
                </div> 
                <TextField required className='inputId' name='email' id='email' autoComplete="email" placeholder='아이디' defaultValue={remMyId} onChange={(e) => handleInput(e)} />
                <TextField required className='inputPasswd' name='password' id='password' type="password" autoComplete="current-password" placeholder='비밀번호' defaultValue={remMyPw}  onChange={(e) => handleInput(e)} />
                <div className='remeber'>
                    <FormControlLabel control={
                        <Checkbox onChange={(e) => rememberMe(e)} checked={remember} id='remember' />}
                                  label="내 로그인 정보 기억하기" style={{color:'#efefef'}} />
                </div>
                <ColorButton type="submit" variant='contained' className='login-Button'  sx={{fontSize: 22, fontColor: '#721E1E', fontWeight: 'bold', borderColor: '#fff !important'}}>로 그 인</ColorButton>
                <div className="login-first" style={{color: '#fff', top: topSize2, right: rightSizeFirst}}>처음 방문이시면 ! &nbsp;&nbsp;</div>
                <div className="login-join" style={{top: topSize2, left: leftSizeJoin, color:'#cfcfcf'}}><Link href="/memberjoin" underline="none">가입신청</Link></div>
                <div className='login-myId' style={{top: topSize1, left: rightSizeMyId}}><Link href="/findmyid" underline="none">아이디 찾기</Link></div>
                <div className="login-myPw" style={{top: topSize1, left: leftSizeMyPw}}><Link href="/findpassword" underline="none">비밀번호 찾기</Link></div>
            </div>
        </Box>

        <Dialog open={open} onClose={handleClose} id="alert-dialog-title">
            {dialogTitleText}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{dialogMessage}</DialogContentText>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button variant="contained" onClick={handleClose}>확 인</Button>
            </DialogActions>
        </Dialog>
        </>
    );
}
export default Login;