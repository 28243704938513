
/******************************************************************
 * 
 *   baccus 회사명을 축약 단축해서 순수 회사명만 표출해준다.
 *   2022.10.27 ~ 2022.10.27
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

export function CompanyNameRedefine (companyName) {

    if(companyName === null) {
        return '';
    }

    if(companyName.indexOf('하이트') !== -1 || companyName.indexOf('진로') !== -1) {
        return '하이트진로(주)';
    }
    else if(companyName.indexOf('OB') !== -1 || companyName.indexOf('오비') !== -1) {
        return '오비맥주';
    }
    else if(companyName.indexOf('한라산') !== -1) {
        return '(주)한라산';
    }
    else if(companyName.indexOf('롯데칠성') !== -1) {
        return '롯데칠성음료';
    }
    else if(companyName.indexOf('제주맥주') !== -1) {
        return '제주맥주';
    }
    else if(companyName.indexOf('보해') !== -1) {
        return '보해양조(주)';
    }
    else if(companyName.indexOf('골든') !== -1) {
        return '주식회사 골든블루';
    }
    else {
        return companyName;
    }
}