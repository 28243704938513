/******************************************************************
 * 
 *   baccus LayOut 모듈 (화면의 Layout을 결정한다)
 *   2022.10.01 ~ 2022.10.12
 *   
 *   Copyright 2022 by YoungBin KO.
 * 
 * ****************************************************************/

import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { loginState, userState } from '../store/user-store';

import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Badge from '@mui/material/Badge';

import MenuIcon from '@mui/icons-material/Menu';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import LogoutIcon from '@mui/icons-material/Logout';

import Drawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

import TotalMenu from './subpage/TotalMenu';
import ConfirmDialog from '../common-lib/ConfirmDialog';


const bull = (
    <Box
      component="span" sx={{ display: 'inline-block', mx: '1px', transform: 'scale(1)', margin: '10 10 10 10px' }}
    >
      •
    </Box>
);

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
  ))({
    '& .MuiTabs-indicator': {
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
      maxWidth: 30,
      width: '100%',
      backgroundColor: '#ffffff',
    },
  });
  
  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(0),
    color: 'rgba(255, 255, 255, 0.7)',
    '&.Mui-selected': {
      color: '#fff',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  }));
  

function Layout() {

    const [ isLogin, setLoginState ] = useRecoilState(loginState);

    const [ value, setValue ] = useState('Home');
    const [ tabValue, setTabValue ] = useState('1');
    const [ drawerState, setDrawerState ] = useState( false );
    const [ drawerState2, setDrawerState2 ] = useState( false );
    const [ open, setOpen ] = useState(false);  // dialog Y or N Confirm
    const [ dialogMessage, setDialogMessage ] = useState('');

    const [ { userId, userName, shopName, shopCode, shopNumber }, setUserState ] = useRecoilState(userState);

    // const toggleDrawer = (value) => {
    //   console.log(value)
    //   if(value === 'Settings')
    //     setDrawerState(prevState => !prevState);
    // };

    const navigate = useNavigate();

    const toggleDrawer2 = () => setDrawerState2(prevState => !prevState);

    useEffect(() => {
      if(value === 'Settings') {
        // console.log(value)
        // console.log('토글 실행');
        setDrawerState(true);
        setTabValue('5');
      }
      else {
        setDrawerState(false);
      }

      if(value === 'Home') setTabValue('1');
      if(value === 'Favorite') setTabValue('2');
      if(value === 'ShoppingBag') setTabValue('3');
      if(value === 'AccountBook') setTabValue('4');

      // console.log(drawerState)
    }, [value]);

    useEffect(() => {
      if(tabValue === '1') {
        setDrawerState(false);
        setValue('Home');
        navigate('/');
      }
      if(tabValue === '2') {
        setDrawerState(false);
        setValue('Favorite');
        navigate('/ordersheet');
      }
      if(tabValue === '3') {
        setDrawerState(false);
        setValue('ShoppingBag');
        navigate('/shoppingbag');
      }
      if(tabValue === '4') {
        setDrawerState(false);
        setValue('AccountBook');
        navigate('/accountbook');
      }
    }, [tabValue])

    const onConfirm = () => {
      sessionStorage.removeItem('SSID');
      sessionStorage.removeItem("shoppingList")
      sessionStorage.removeItem("shoppingList2")
      setLoginState({ isLogin: false });
      navigate('/', {replace:true});
    }

    const logout = () => {
      setDialogMessage('로그아웃 하시겠습니까 ?');
      setOpen(true);
    }

    return (
        <>
        <div>
          <ConfirmDialog title="" open={open} setOpen={setOpen} onConfirm={onConfirm}>{dialogMessage}</ConfirmDialog>
        </div>
        <div className="Layout">
            {/* App Bar */}
            <Paper sx={{ position: 'fixed', top: 0, left: 0, right: 0, minHeight: 121, zIndex: 88888, backgroundColor: '#181A70', borderRadius: 0 }} elevation={0}>
              {/* <Box sx={{ flexGrow: 1, minHeight: 60 }}> */}
                <AppBar position="static" elevation={0} style={{backgroundColor: '#181A70', minHeight: 120, zIndex: 88887}}>
                    <Toolbar>
                        <Box 
                            //wrap='wrap'
                            justifyContent="space-between"
                            alignItems="center"
                            flexDirection={{ xs: 'row' }}
                            sx={{ display:'flex', fontSize: '12px', width: '100%' }}
                        >   
                            <Box display="flex" justifyContent="flex-start">
                              {/* 사용자 이름이나 가게명을 넣어준다. */}
                              {shopName} <br />
                              {userName} 님  어서오세요!
                            </Box>
                            <Box display="flex" justifyContent="flex-end" alignItems="center">
                                {/* <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                   <SearchIcon />
                                </IconButton> */}
                                <IconButton
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                   <Badge badgeContent={12} color='error'><NotificationsNoneIcon /></Badge>
                                </IconButton>
                                <IconButton onClick={() => toggleDrawer2()}
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                   <MenuIcon />
                                </IconButton>
                                <IconButton onClick={logout}
                                    size="large"
                                    edge="start"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                   <LogoutIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </Toolbar>
                    <StyledTabs variant='standard' value={tabValue} TabIndicatorProps={{ sx:{ backgroundColor: 'white', height: '4px', maxWidth: '40px', width: '80%'}}}
                          onChange={(event, newValue) => { setTabValue(newValue); }}
                    >
                                <img src='baccus64.png' />
                        <StyledTab value="1" label={<div style={{fontSize:18}}>홈</div>} style={{ minWidth: 50 }} />
                        <StyledTab value="2" label={<div style={{fontSize:18}}>주문</div>} style={{ minWidth: 50 }} />
                        <StyledTab value="3" label={<div style={{fontSize:18}}>장바구니</div>} style={{ minWidth: 50 }} />
                        <StyledTab value="4" label={<div style={{fontSize:18}}>거래장</div>} style={{ minWidth: 50 }} />
                    </StyledTabs>
                </AppBar>
              {/* </Box>  */}
            </Paper>
            
            <Outlet />

            {/* Bottom Navigation */}
            {/* <Box sx={{ pb: 7 }}>
                <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 99988 }} elevation={3} className='bottomNavigation'>
                    <BottomNavigation
                      value={value}
                      // onClick={toggleDrawer('bottom', false)}
                      onChange={(event, newValue) => {
                      setValue(newValue);
                      }}
                    >
                        <BottomNavigationAction label={bull} value="Home" icon={<HomeIcon />} />
                        <BottomNavigationAction label={bull} value="Favorite" icon={<FavoriteIcon />} />
                        <BottomNavigationAction label={bull} value="ShoppingBag" icon={<ShoppingBagIcon />} />
                        <BottomNavigationAction label={bull} value="AccountBook" icon={<LibraryBooksIcon />} />
                        <BottomNavigationAction label={bull} value="Settings" icon={<SettingsIcon />} >눌러봐</BottomNavigationAction>
                    </BottomNavigation>
                </Paper>
            </Box>             */}
            <Box  sx={{zIndex: 99995, bgcolor:'#ffffff', borderTopLeftRadius : 10}}>

            {/* 설정 화면 팝업   Drawer  */}
            <Drawer sx={{zIndex: 77777, }}
            anchor='bottom'
            open={drawerState}
            // onClose={(value) => toggleDrawer()}
            >
              <Box sx={{margin: 3, borderTopLeftRadius:10, height:620}}>
            Drawer Toggle 가능하게 바꾸고...사이즈 조정이 되도록
            모바일마다 다른 사이즈가 될 수 있으니...
            inner Size 를 받아와서 상단 탭을 제외한 사이즈로 펼쳐지도록 해야한다.<br />
            스위블 기능이 가능하도록 해야함(손으로 밀었을때 닫히도록)
              </Box>
          </Drawer>

          {/* 전체서비스 / 전체메뉴 팝업  Drawer   */}
          <Drawer sx={{zIndex: 99999 , boxSizing: 'border-box'}} 
            PaperProps={{ sx: {width: '100%'}}}
            anchor='right'
            open={drawerState2}
          >
            <TotalMenu onToggleDrawer={toggleDrawer2}/>
          </Drawer>

          </Box>

          
        </div>
        </>
    );
}
export default Layout;